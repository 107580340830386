<template>
  <div class="page">
    <div class="title">白条管理</div>

    <!-- table -->
    <el-table
      id="table"
      :data="whiteList"
      :header-cell-style="tableHeaderStyle"
      :cell-style="tableCellStyle"
      style="width: 100%"
    >
      <el-table-column prop="real_name" label="姓名"></el-table-column>
      <el-table-column prop="job_number" label="工号"></el-table-column>
      <el-table-column prop="duty_name" label="部门"></el-table-column>
      <el-table-column prop="station_name" label="岗位"></el-table-column>
      <el-table-column prop="idcard" label="身份证"></el-table-column>
      <el-table-column prop="amount" label="申请金额"></el-table-column>
      <el-table-column prop="create_time" label="申请时间"></el-table-column>
      <el-table-column prop="audit_time" label="审核时间"></el-table-column>
      <el-table-column prop="status" label="审核状态">
        <template #default="{ row }">
          {{ statusa(row) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="130" fixed="right">
        <template v-slot:default="scope">
          <div style="display: flex; justify-content: center">
            <!-- <span class="s_operation" >操作</span> -->
            <span
              class="s_operation"
              @click="examinedResovle(scope.row.id, scope.row.status, 1)"
              >审核通过
            </span>
            <span
              class="s_operation"
              @click="examinedReject(scope.row.id, scope.row.status, 2)"
            >
              审核拒绝</span
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.size"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>

    <el-dialog
      title="拒绝审核通过原因"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <!-- <div>
      <span class="red">*</span><span class="remark">备注:</span>
      <textarea name="remark" required cols="30" rows="10"></textarea>
    </div> -->
      <el-form>
        <el-form-item label="备注：">
          <el-input type="textarea" v-model="remark"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="subumit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  inject: ["reload"],
  data() {
    return {
      dialogVisible: false,
      remark: "",
      whiteId: "",
      whiteType: "",
      search: {
        keyword: "",
      },
      whiteList: [],
      page: {
        size: 15,
        page: 1,
        total: 0,
      },

      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
    };
  },
  computed: {},
  created() {
    this.loadData = this.loadDataFactory();
    this.loadData();
  },
  methods: {
    ...mapActions("enterprise", [
      "getWhiteList",
      "examinedWhite",
      // "addDepartment",
      // "editDepartment",
      // "delDepartment",
    ]),
    statusa(row) {
      const statusMap = {
        0: "待审核",
        1: "通过",
        2: "拒绝",
      };
      return statusMap[row.status];
    },
    handleClose() {
      this.dialogVisible = false;
    },
    subumit() {
      let params = {};
      params.id = this.whiteId;
      params.type = this.whiteType;
      params.remark = this.remark;
      if (this.remark == "") {
        this.$message.error("备注内容不能为空");
        return;
      }
      this.examinedWhite(params).then((res) => {
        if (res.code == 200) {
          this.dialogVisible = false;
          this.$message.success("已成功通过审核！");
          // this.loadDataFactory()();
        } else {
          this.dialogVisible = false;
          this.$message.success("已拒绝该申请");
        }
        console.log("examinedWhite", res);
      });
      this.loadDataFactory()();
    },

    loadDataFactory(keyword = null) {
      let params = {};
      if (keyword) {
        params.keywords = keyword;
      }
      return () => {
        params.page = this.page.page;
        params.size = this.page.size;
        let loading = this.$loading();
        this.getWhiteList(params)
          .then((res) => {
            console.log("getWhiteList", res.data);
            this.whiteList = res.data.data.list;
            this.page.total = res.data.data.total;
            this.$nextTick(() => {
              loading.close();
            });
          })
          .catch((e) => {
            this.$message.error(e.msg);
            loading.close();
          });
      };
    },

    /**
     * 同意审核事件
     */
    examinedResovle(id, status, type) {
      console.log("我点击了");
      let params = {};
      params.id = id;
      params.type = type;
      if (status == 0) {
        console.log("params", params);
        this.examinedWhite(params).then((res) => {
          this.status = res.data.status;
          this.$message.success("审核通过");
          this.$forceUpdate();
          this.loadDataFactory();
          this.$router.go(0);
        });
      } else {
        this.$message.warning("该用户非审核状态");
      }
    },

    /**
     * 拒绝审核事件
     */
    examinedReject(id, status, type) {
      if (status != 0) {
        this.$message.warning("该用户非审核状态");
      } else {
        this.dialogVisible = true;
        this.whiteId = id;
        this.whiteType = type;
        this.$forceUpdate();
        this.loadDataFactory();
      }
    },

    /**
     * 搜索
     */
    toSearch() {
      this.page.page = 1;
      // 生产带有搜索参数的函数
      this.loadData = this.loadDataFactory(this.search.keyword);
      this.loadData();
    },

    add() {
      this.$prompt("请输入部门名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /.+/,
        inputErrorMessage: "请输入部门名称",
      }).then(({ value }) => {
        this.addDepartment({
          name: value,
        })
          .then((res) => {
            console.log(res);
            this.$message.success(res.data.msg);
            this.loadData();
          })
          .catch((e) => {
            console.error(e);
            this.$message.error(e.msg);
          });
      });
    },

    edit(row) {
      this.$prompt("请输入部门名称", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /.+/,
        inputErrorMessage: "请输入部门名称",
        inputValue: row.name,
      }).then(({ value }) => {
        this.editDepartment({
          id: row.id,
          name: value,
        })
          .then((res) => {
            console.log(res);
            this.$message.success(res.data.msg);
            this.loadData();
          })
          .catch((e) => {
            console.error(e);
            this.$message.error(e.msg);
          });
      });
    },

    del(row) {
      this.$confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delDepartment({
          id: row.id,
        })
          .then((res) => {
            console.log(res);
            this.$message({
              type: "success",
              message: res.data.msg,
            });
            this.loadData();
          })
          .catch((e) => {
            console.error(e);
            this.$message({
              type: "error",
              message: e.msg,
            });
            this.loadData();
          });
      });
    },

    check(row) {
      this.$router.push({
        name: "PostList",
        params: {
          pid: row.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  padding-bottom: 50px;
  min-height: calc(100% - 50px);
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .el-date-editor {
    height: 36px;
    margin-right: 10px;

    ::v-deep .el-input__inner {
      height: 36px;
    }
  }

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_select {
      width: 80px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }

      ::v-deep .el-input__icon {
        line-height: 36px;
      }
    }

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
    > div:not(:first-child) {
      margin-left: 20px;
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.operations {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    color: #406bff;
    white-space: nowrap;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    > div {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.s_operation {
  color: #4db9d5;
  margin-right: 10px;
}
.s_operation:hover {
  cursor: pointer;
}
.red {
  color: #f00;
  float: left;
  left: 0;
}
.remark {
  float: left;
}
textarea {
  margin-left: 20px;
}
</style>
